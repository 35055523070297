<template>
  <div class="container">
    <div class="search" style="display:flex">
      <el-form ref="form" style="flex:1" :inline="true" :model="search" class="demo-form-inline">
        <el-form-item>
          <el-input v-model="search.cnsuo" @change="Search" placeholder="语言简码"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="search.cocn" @change="Search" placeholder="语言名称"></el-input>
        </el-form-item>
      </el-form>
      <div>
        <el-button type="primary" size="small" @click="addLanguage">增加</el-button>
      </div>
    </div>
    <!-- 列表 -->
    <el-table border v-bind:data="list">
      <!-- <el-table-column label="序号" type="index" width="50"></el-table-column> -->
      <el-table-column label="语言简码">
        <template v-slot="scope">
          <span>{{ scope.row.code }}</span>
        </template>
      </el-table-column>
      <el-table-column label="语言名称">
        <template v-slot="scope">
          <span>{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="用户创建时间">
        <template v-slot="scope">
          <span>{{ timeToDate(scope.row.create_time) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="用户修改时间">
        <template v-slot="scope">
          <span>{{ timeToDate(scope.row.update_time) }}</span>
        </template>
      </el-table-column> -->
      <el-table-column label="操作">
        <template v-slot="scope">
          <el-button type="primary" size="small" @click="editLanguage(scope.row)">修改</el-button>
          <el-button type="danger" size="small" @click="deleteLanguage(scope.row.code)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="handleCurrentChange"
      v-model:currentPage="search.page"
      :page-size="search.limit"
      layout="total, prev, pager, next"
      :total="total">
    </el-pagination>

    <!-- 新增修改 -->
    <el-dialog :title="title+'语言信息'" v-model="dialogFormVisible" width="600px">
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="语言标识" prop="code" label-width="100px">
          <el-input v-model="form.code" :disabled="edit" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="语言名称" prop="name" label-width="100px">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" v-if="!edit" @click="Validate(addconfirm)">新增</el-button>
          <el-button type="primary" v-if="edit" @click="Validate(editconfirm)">修改</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: '',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      title: '',
      search: {
        code: '',
        page: 1,
        limit: 10,
        kname: '',
      },
      total: 0,
      list: [
      ],
      dialogFormVisible: false,
      form: {
        code: '',
        name: '',
      },
      rules: {
        name: [{ required: true, message: '请输入语言名称', trigger: 'blur' }],
        code: [{ required: true, message: '请输入语言标识', trigger: 'blur' }]
      },
      edit: false
    }
  },
  computed: {},
  watch: {},
  methods: {
    handleCurrentChange(val) {
      this.search.page = val
      this.Search()
    },
    Search(){
      this.api.LanguageList(this.search).then(res => {
        if (res.data.code == 200) {
          this.list = res.data.data.data
          this.total = res.data.data.page_info.total
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    addLanguage () {
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
      this.form={
        code: '',
        name: '',
      }
      this.title = '新增'
      this.edit = false
      this.dialogFormVisible = true
    },
    editLanguage(item) {
      this.edit = true
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
      this.title = '修改'
      this.api.LanguageDetail({
        code: item.code
      }).then(res => {
        if (res.data.code == 200) {
          this.dialogFormVisible = true
          this.form = res.data.data
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    Validate: function (call) {
      this.$refs['form'].validate(res => {
        if (res && call) {
          call()
        }
      })
    },
    deleteLanguage(item) {
      this.$confirm('是否删除该语言?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 删除请求
        this.api.LanguageDelete({
          code: item
        }).then(res => {
          if (res.data.code == 200) {
            this.Search()
            this.$message.success('删除成功')
            this.dialogFormVisible = false
            return
          }
          this.$message.error('删除失败'+ res.data.msg)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消删除'
        });
      })
    },
    addconfirm () {
      this.update()
    },
    update(){
      this.api.LanguageUpdate(this.form).then(res => {
        if (res.data.code == 200) {
          this.Search()
          this.$message.success(this.title + '成功')
          this.dialogFormVisible = false
          return
        }
        this.$message.error(this.title + '失败' + res.data.msg)
      })
    },
    editconfirm () {
      this.update()
    },
  },
  filters: {},
  mounted () { },
  created () {
    this.Search()
  }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
</style>
